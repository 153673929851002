import logoGoogle from "~/images/logos/google.svg";
import logoRev from "~/images/logos/rev.svg";
import logoSiuben from "~/images/logos/siuben.png";
import { Button } from "./Button";

interface Role {
  company: string;
  title: string;
  logo?: string;
  website?: string;
  start: string | { label: string; dateTime: string };
  end: string | { label: string; dateTime: string };
}

function Role({ role }: { role: Role }) {
  let startLabel =
    typeof role.start === "string" ? role.start : role.start.label;
  let startDate =
    typeof role.start === "string" ? role.start : role.start.dateTime;

  let endLabel = typeof role.end === "string" ? role.end : role.end.label;
  let endDate = typeof role.end === "string" ? role.end : role.end.dateTime;

  const content = (
    <>
      <div className="relative mt-1 flex h-10 w-10 flex-none items-center justify-center rounded-full shadow-md shadow-zinc-800/5 ring-1 ring-zinc-900/5 dark:border dark:border-zinc-700/50 dark:bg-zinc-800 dark:ring-0">
        {role.logo ? (
          <img src={role.logo} alt="" className="h-7 w-7" />
        ) : (
          <span className="text-sm font-medium text-zinc-500 dark:text-zinc-400">
            {role.company
              .split(" ")
              .map((word) => word[0])
              .join("")
              .toUpperCase()}
          </span>
        )}
      </div>
      <div className="flex flex-auto flex-col justify-center">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-zinc-900 dark:text-zinc-100">
              {role.company}
            </p>
            <p className="text-xs text-zinc-500 dark:text-zinc-400">
              {role.title}
            </p>
          </div>
          <p
            className="text-xs text-zinc-400 dark:text-zinc-500"
            aria-label={`${startLabel} until ${endLabel}`}
          >
            <time dateTime={startDate}>{startLabel}</time>{" "}
            <span aria-hidden="true">—</span>{" "}
            <time dateTime={endDate}>{endLabel}</time>
          </p>
        </div>
      </div>
    </>
  );

  return (
    <li className="flex gap-4">
      {role.website ? (
        <a
          href={role.website}
          target="_blank"
          rel="noopener noreferrer"
          className="flex w-full gap-4 cursor-pointer hover:bg-zinc-50 dark:hover:bg-zinc-800/50 transition duration-300 ease-in-out rounded-lg p-2"
        >
          {content}
        </a>
      ) : (
        <div className="flex w-full gap-4 p-2">{content}</div>
      )}
    </li>
  );
}

function ArrowDownIcon(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <svg viewBox="0 0 16 16" fill="none" aria-hidden="true" {...props}>
      <path
        d="M4.75 8.75 8 12.25m0 0 3.25-3.5M8 12.25v-8.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function BriefcaseIcon(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M2.75 9.75a3 3 0 0 1 3-3h12.5a3 3 0 0 1 3 3v8.5a3 3 0 0 1-3 3H5.75a3 3 0 0 1-3-3v-8.5Z"
        className="fill-zinc-100 stroke-zinc-400 dark:fill-zinc-100/10 dark:stroke-zinc-500"
      />
      <path
        d="M3 14.25h6.249c.484 0 .952-.002 1.316.319l.777.682a.996.996 0 0 0 1.316 0l.777-.682c.364-.32.832-.319 1.316-.319H21M8.75 6.5V4.75a2 2 0 0 1 2-2h2.5a2 2 0 0 1 2 2V6.5"
        className="stroke-zinc-400 dark:stroke-zinc-500"
      />
    </svg>
  );
}

function Resume() {
  let resume: Array<Role> = [
    {
      company: "Google",
      title: "SRE-SWE",
      logo: logoGoogle,
      website: "https://google.com",
      start: "August 2020",
      end: {
        label: "Present",
        dateTime: new Date().getFullYear().toString(),
      },
    },
    {
      company: "Rev.com",
      title: "Software Engineer",
      logo: logoRev,
      website: "https://rev.com",
      start: "July 2015",
      end: "June 2020",
    },
    {
      company: "SIUBEN",
      title: "SWE-Consultant",
      logo: logoSiuben,
      website: "https://siuben.gob.do",
      start: "Oct 2014",
      end: "Feb 2015",
    },
    {
      company: "BANAK CO",
      title: "Software Engineer",
      start: "Jan 2014",
      end: "Sept 2014",
    },
    {
      company: "CREDLI",
      title: "Web Developer",
      start: "March 2013",
      end: "Dec 2013",
    },
    {
      company: "TEKNOWLOGIC",
      title: "Junior SWE",
      website: "https://www.teknowlogic.com",
      start: "Jan 2012",
      end: "Feb 2013",
    },
  ];

  return (
    <div className="rounded-2xl border border-zinc-100 p-6 dark:border-zinc-700/40">
      <h2 className="flex text-sm font-semibold text-zinc-900 dark:text-zinc-100">
        <BriefcaseIcon className="h-6 w-6 flex-none" />
        <span className="ml-3">Work</span>
      </h2>
      <ol className="mt-6 space-y-4">
        {resume.map((role, roleIndex) => (
          <Role key={roleIndex} role={role} />
        ))}
      </ol>
      <Button
        to="/resume.pdf"
        target="_blank"
        rel="noopener noreferrer"
        variant="secondary"
        className="group mt-6 w-full"
      >
        Download CV
        <ArrowDownIcon className="h-4 w-4 stroke-zinc-400 transition group-active:stroke-zinc-600 dark:group-hover:stroke-zinc-50 dark:group-active:stroke-zinc-50" />
      </Button>
    </div>
  );
}

export default Resume;
